import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import cn from 'classnames';

import { useGetOpenedContactsAllQuery, useLazyGetCsvQuery, useLazyGetGoogleSheetsQuery } from 'services';
import { Pagination, useTableSelection, TableSelection, Table } from 'features/table';
import { Button, Icon, SelectProfileTooltip, Text } from 'shared/components/ui';
import { useFilters, useSearch, useGetCandidates } from 'features/search/hooks';
import { OpenedContactIdAndEmail, ProgAIProfile, ProgAIResults } from 'shared/generated-models';
import { ResultsEmpty, ResultsError, ResultsSkeleton, SearchUpdate, ProfileRow } from 'features/search/components';
import { ProjectMenu } from 'features/projects';
import { CandidateModal, MultipleContactsModal } from 'features/candidate';
import { Analytics } from 'features/analytics';
import { useIsFree } from 'features/auth';
import { CsvPushMenu } from 'features/csv-export';

import styles from './index.module.scss';

export const ResultsList = () => {
	const [candidate, setCandidate] = useState<ProgAIResults | null>(null);
	const [openedContactsMap, setOpenedContactsMap] = useState<Record<number, string> | null>(null);
	const [includedLocked, setIncludedLocked] = useState<number[]>([]);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const location = useLocation();

	const isFree = useIsFree();

	const search = useSearch();
	const filters = useFilters();
	const ref = useRef<HTMLDivElement>(null);

	const [getCsv] = useLazyGetCsvQuery();
	const [getGoogleSheetsUrl, { data: sheetUrl }] = useLazyGetGoogleSheetsQuery();

	useEffect(() => {
		if (sheetUrl?.url) {
			window.open(sheetUrl.url, '_blank');
		}
	}, [sheetUrl]);

	const closeCandidateModal = () => {
		setCandidate(null);
	};

	useEffect(() => {
		ref.current?.scroll({ top: 0, behavior: 'smooth' });
	}, [filters.page]);

	const { data, isFetching, isError, totalLoaded } = useGetCandidates();

	const { data: openedContacts, isFetching: isOpenedContactsFetching } = useGetOpenedContactsAllQuery();

	useEffect(() => {
		if (openedContacts) {
			const openedContactsMap: Record<number, string> = openedContacts.reduce(
				(acc, contact: OpenedContactIdAndEmail) => {
					acc[contact.id] = contact.primary_email || '';
					return acc;
				},
				{} as Record<number, string>
			);
			setOpenedContactsMap(openedContactsMap);
		}
	}, [openedContacts]);

	useEffect(() => {
		data && Analytics.trackSearch(data);
	}, [data]);

	const {
		selectAll,
		isAllSelected,
		toggle,
		isSelected,
		someSelected,
		count,
		selectPage,
		selectCount,
		included,
		excluded,
		togglePage,
		clearSelection,
		selectedCount,
		mode
	} = useTableSelection<ProgAIProfile>({
		data: data?.results.map(row => row.profile),
		totalCount: data?.results_count,
		max: data?.results_count
	});

	useEffect(() => {
		const locked: number[] = [];
		included.forEach(idx => {
			if (openedContactsMap && openedContactsMap.hasOwnProperty(idx)) {
				return;
			}
			locked.push(idx);
		});
		setIncludedLocked(locked);
	}, [included, openedContactsMap]);

	useEffect(() => {
		if (location.state && location.state.refresh) {
			clearSelection();
		}
	}, [location.key]);

	const handleExportCsv = (isFull: boolean) => {
		Analytics.trackCsvExport(count, 'Search');
		getCsv({
			ids: included,
			filters,
			excluded_ids: excluded,
			top: selectedCount || undefined,
			is_full_profiles: isFull
		});
	};

	const handleExportGoogleSheets = (isFull: boolean) => {
		Analytics.trackGoogleSheetsExport(count, 'Search');
		getGoogleSheetsUrl({
			ids: included,
			filters,
			excluded_ids: excluded,
			top: selectedCount || undefined,
			is_full_profiles: isFull
		});
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const handleContacts = async () => {
		setIsModalOpen(true);
	};

	if (isFetching) return <ResultsSkeleton />;

	if (isError || !data) return <ResultsError />;

	if (data.results.length === 0) return <ResultsEmpty />;

	return (
		<section className={styles.container}>
			<div className={styles.info}>
				<Text variant="grotesk/14/bold">
					{data.results_count ? `${data.results_count} candidates` : `More than ${totalLoaded} candidates`}
				</Text>
				<div className={styles.infoButtons}>
					{/* <Button onClick={handleContacts} type="default" disabled={includedLocked.length === 0}>
						{includedLocked.length === 0 ? 'Unlock' : `Unlock (${includedLocked.length})`}
					</Button> */}
					<SelectProfileTooltip isDisabled={!someSelected}>
						<span>
							<ProjectMenu
								candidates={{
									count,
									ids: included,
									excluded_ids: excluded,
									top: selectedCount || undefined
								}}
								disabled={!someSelected}>
								<Button type="default" className={styles.project} suffix={<Icon icon="arrow-down" />}>
									Add to project
								</Button>
							</ProjectMenu>
						</span>
					</SelectProfileTooltip>
					<SelectProfileTooltip isDisabled={!someSelected}>
						<span>
							<CsvPushMenu
								disabled={!someSelected}
								handleExportCsv={handleExportCsv}
								handleExportGoogleSheets={handleExportGoogleSheets}
								candidates={{
									count,
									ids: included,
									excluded_ids: excluded,
									top: selectedCount || undefined
								}}>
								<Button type="primary" disabled={!someSelected} suffix={<Icon icon="arrow-down" />}>
									Export to
								</Button>
							</CsvPushMenu>
						</span>
					</SelectProfileTooltip>
				</div>
			</div>
			<Table className={styles.list} ref={ref}>
				<div className={cn(styles.header, { [styles.headerBorder]: !isFree })}>
					<div className={styles.listControl}>
						<TableSelection
							controls={{
								main: { togglePage, count, isAllSelected },
								extra: { selectPage, selectAll, selectCount }
							}}
							hint="Select candidates"
						/>
						<Pagination
							page={filters.page}
							onChange={p => search({ ...filters, page: p })}
							onPageCount={data.results.length}
							total={data.results_count}
						/>
					</div>
					<SearchUpdate />
				</div>
				{data.results.map((candidate, idx) => (
					<ProfileRow
						checkbox={{
							checked: isSelected({ row: candidate.profile, page: filters.page, idx }),
							onChange: () => toggle({ row: candidate.profile, page: filters.page, idx }),
							mode
						}}
						key={candidate.profile.id}
						onClick={() => setCandidate(candidate)}
						candidate={candidate}
						openedContactsMap={openedContactsMap}
					/>
				))}
				<div className={styles.footer}>
					<div className={styles.footerText}>
						<Text variant="inter/13/regular" color="darker_grey">
							End of page
						</Text>
					</div>
					<div className={styles.footerPagination}>
						<Pagination
							page={filters.page}
							onChange={p => search({ ...filters, page: p })}
							onPageCount={data.results.length}
							total={data.results_count}
						/>
					</div>
				</div>
			</Table>
			{candidate && (
				<CandidateModal
					candidates={data.results}
					setCandidate={setCandidate}
					candidate={candidate}
					onClose={closeCandidateModal}
					total={data.results_count}
					page={filters.page}
					onChange={p => search({ ...filters, page: p })}
				/>
			)}
			{isModalOpen && <MultipleContactsModal onClose={closeModal} ids={includedLocked} />}
		</section>
	);
};
