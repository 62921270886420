import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Pagination, Table, TableSelection, useTableSelection } from 'features/table';
import { OpenedContactIdAndEmail, OpenedContactShort } from 'shared/generated-models';
import {
	projectsService,
	useGetOpenedContactsAllQuery,
	useLazyGetProjectCandidatesQuery,
	useLazyGetProjectContactsCsvQuery,
	useLazyGetProjectContactsGoogleSheetsQuery
} from 'services';
import { MultipleContactsModal } from 'features/candidate';
import {
	CandidateRow,
	ProjectHeader,
	ProjectModal,
	DeleteCandidatesButton,
	ProjectCandidatesListEmpty,
	ProjectSkeleton
} from 'features/projects';
import { CsvPushMenu } from 'features/csv-export';
import { Analytics } from 'features/analytics';
import { environment } from 'environments/environment';
import { useEffectUnsafe } from 'shared/hooks';
import { Button, Icon, SelectProfileTooltip, Text } from 'shared/components/ui';
import { IntergationsPushMenu } from 'features/integrations';

import styles from './index.module.scss';

const PAGE_SIZE = 20;

export const ProjectCandidatesList = () => {
	const { id } = useParams();
	const numId = Number(id);
	const [page, setPage] = useState(0);
	const [contact, setContact] = useState<OpenedContactShort | null>(null);
	const [addingProgress, setAddingProgress] = useState<number | null>(null);
	const [addingCount, setAddingCount] = useState<number | null>(null);
	const [openedContactsMap, setOpenedContactsMap] = useState<Record<number, string> | null>(null);
	const [updateList] = projectsService.useLazyGetProjectsListQuery();

	const [includedLocked, setIncludedLocked] = useState<number[]>([]);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const [getCsv] = useLazyGetProjectContactsCsvQuery();
	const [getGoogleSheetsUrl, { data: sheetUrl }] = useLazyGetProjectContactsGoogleSheetsQuery();
	const [getCandidates, { data, isFetching, isSuccess, isError }] = useLazyGetProjectCandidatesQuery();

	const ref = useRef<HTMLDivElement>(null);

	const ws = useRef<WebSocket | null>(null);

	useEffectUnsafe(() => {
		if (!isSuccess && page === 0) {
			const backendUrl = environment.apiUrl.replace('https://', 'wss://').replace('.ai/api', '.ai:443/ws');

			ws.current = new WebSocket(`${backendUrl}/projects/${numId}/`);

			if (ws.current) {
				ws.current.onmessage = message => {
					const data = JSON.parse(message.data);
					if (data.status === 'Processing') {
						setAddingProgress(Number(data.progress));
						setAddingCount(Number(data.added));
					}
					if (data.status === 'Complete' || data.status === 'not_in_progress') {
						setAddingProgress(null);
						setAddingCount(null);
						updateList();
						getCandidates({
							project_pk: numId,
							page: page + 1
						});
						ws.current?.close();
					}
				};
			}

			return () => ws.current?.close();
		}
	}, [numId]);

	useEffect(() => {
		if (page > 0 || (page === 0 && isSuccess)) {
			getCandidates(
				{
					project_pk: numId,
					page: page + 1
				},
				true
			);
			return;
		}
	}, [page]);

	const { data: openedContacts, isFetching: isOpenedContactsFetching } = useGetOpenedContactsAllQuery();

	useEffect(() => {
		if (openedContacts) {
			const openedContactsMap: Record<number, string> = openedContacts.reduce(
				(acc, contact: OpenedContactIdAndEmail) => {
					acc[contact.id] = contact.primary_email || '';
					return acc;
				},
				{} as Record<number, string>
			);
			setOpenedContactsMap(openedContactsMap);
		}
	}, [openedContacts]);

	const handleContact = (contact: OpenedContactShort) => {
		setContact(contact);
	};

	const closeModal = () => {
		setContact(null);
	};

	const {
		selectAll,
		isAllSelected,
		count,
		selectPage,
		selectCount,
		togglePage,
		isSelected,
		toggle,
		included,
		excluded,
		selectedCount,
		someSelected,
		mode,
		clearSelection
	} = useTableSelection<OpenedContactShort>({
		data: data?.results,
		totalCount: data?.count
	});

	useEffect(() => {
		const locked: number[] = [];
		included.forEach(idx => {
			if (!data) return;
			const profile = data.results!.find(item => item.id === idx);
			if (!profile) return;

			const prog_ai_id = profile.prog_ai_id;

			if (openedContactsMap && openedContactsMap.hasOwnProperty(prog_ai_id)) {
				return;
			}
			locked.push(prog_ai_id);
		});
		setIncludedLocked(locked);
	}, [data, included, openedContactsMap]);

	const closeContactsModal = () => {
		setIsModalOpen(false);
	};

	const handleContacts = async () => {
		setIsModalOpen(true);
	};

	useEffect(() => {
		if (sheetUrl?.url) {
			window.open(sheetUrl.url, '_blank');
		}
	}, [sheetUrl]);

	const handleExportGoogleSheets = (isFull: boolean) => {
		Analytics.trackGoogleSheetsExport(count, 'Project');
		getGoogleSheetsUrl({
			candidates: {
				ids: included,
				excluded_ids: excluded,
				top: selectedCount || undefined,
				is_full_profiles: isFull,
				filters: {
					page,
					size: PAGE_SIZE
				}
			},
			project_pk: numId
		});
	};

	const handleExportCsv = (isFull: boolean) => {
		Analytics.trackCsvExport(count, 'Project');
		getCsv({
			candidates: {
				ids: included,
				excluded_ids: excluded,
				top: selectedCount || undefined,
				is_full_profiles: isFull,
				filters: {
					page,
					size: PAGE_SIZE
				}
			},
			project_pk: numId
		});
	};

	const handleDelete = () => {
		setPage(0);
		clearSelection();
	};

	if ((!isSuccess && !isError && addingProgress === null) || isFetching) return <ProjectSkeleton />;

	if (addingProgress !== null || (!isFetching && data && data.results?.length === 0))
		return <ProjectCandidatesListEmpty progress={addingProgress} count={addingCount} />;

	return (
		<>
			<ProjectHeader>
				{/* <Button onClick={handleContacts} type="default" disabled={includedLocked.length === 0}>
					{includedLocked.length === 0 ? 'Unlock' : `Unlock (${includedLocked.length})`}
				</Button> */}
				<SelectProfileTooltip isDisabled={!someSelected}>
					<span>
						<IntergationsPushMenu
							projectId={numId}
							disabled={!someSelected}
							candidates={{
								count,
								ids: included,
								excluded_ids: excluded,
								top: selectedCount || undefined
							}}>
							<Button
								disabled={!someSelected}
								type="default"
								className={styles.project}
								suffix={<Icon icon="arrow-down" />}>
								Push to
							</Button>
						</IntergationsPushMenu>
					</span>
				</SelectProfileTooltip>
				<SelectProfileTooltip isDisabled={!someSelected}>
					<span>
						<CsvPushMenu
							disabled={!someSelected}
							handleExportCsv={handleExportCsv}
							handleExportGoogleSheets={handleExportGoogleSheets}
							candidates={{
								count,
								ids: included,
								excluded_ids: excluded,
								top: selectedCount || undefined
							}}>
							<Button type="primary" disabled={!someSelected} suffix={<Icon icon="arrow-down" />}>
								Export to
							</Button>
						</CsvPushMenu>
					</span>
				</SelectProfileTooltip>
			</ProjectHeader>
			<Table ref={ref} className={styles.table}>
				<div className={styles.tableHeader}>
					<div className={styles.controls}>
						<div className={styles.controlsLeft}>
							<TableSelection
								controls={{
									main: { togglePage, count, isAllSelected },
									extra: { selectPage, selectAll, selectCount }
								}}
								hint="Select"
							/>
							<DeleteCandidatesButton
								disabled={!someSelected}
								candidates={{
									count,
									project_pk: numId,
									ids: included,
									excluded_ids: excluded,
									top: selectedCount || undefined
								}}
								onDelete={handleDelete}
							/>
						</div>
						<Pagination page={page} onChange={setPage} total={data?.count || 0} />
					</div>
				</div>
				{data?.results?.map((candidate, idx) => (
					<CandidateRow
						key={candidate.id}
						candidate={candidate}
						onClick={handleContact}
						variant="project"
						checkbox={{
							checked: isSelected({ row: candidate, page: page, idx }),
							onChange: () => toggle({ row: candidate, page: page, idx }),
							mode
						}}
						openedContactsMap={openedContactsMap}
					/>
				))}
				<div className={styles.footer}>
					<div className={styles.footerText}>
						<Text variant="inter/13/regular" color="darker_grey">
							End of page
						</Text>
					</div>
					<div className={styles.footerPagination}>
						<Pagination page={page} onChange={setPage} total={data?.count || 0} />
					</div>
				</div>
			</Table>
			{contact && (
				<ProjectModal
					contact={contact}
					project_pk={numId}
					onClose={closeModal}
					contacts={data?.results}
					setContact={setContact}
					total={data?.count || 0}
					page={page}
					onChange={setPage}
				/>
			)}
			{isModalOpen && <MultipleContactsModal onClose={closeContactsModal} ids={includedLocked} />}
		</>
	);
};
