import { FC } from 'react';
import { Form, Input as AntInput, InputProps } from 'antd';
import { Field, FieldProps } from 'formik';

import { Icon } from 'shared/components/ui/Icon';

import './index.scss';
import { FormFieldProps, FormikFieldProps } from '../Formik';

type Props = FormFieldProps<Omit<InputProps, 'form'>>;

export const FormInput: FC<Props> = ({
	field: { onChange, ...field },
	form: { touched, errors },
	label,
	size,
	...otherProps
}: Props) => {
	const errorMsg = touched[field.name] && (errors[field.name] as string);

	const suffix = errorMsg ? <Icon icon="error" /> : <span />;

	return (
		<Form.Item
			label={label}
			help={errorMsg}
			shouldUpdate
			validateStatus={errorMsg ? 'error' : undefined}
			colon={false}>
			<AntInput {...field} {...otherProps} suffix={suffix} onChange={onChange} size={size} />
		</Form.Item>
	);
};

type FormikProps = FormikFieldProps<InputProps>;

export const FormikInput: FC<FormikProps> = ({ name, ...otherProps }: FormikProps) => {
	return (
		<Field name={name}>
			{({ field, form, meta }: FieldProps) => <FormInput field={field} form={form} meta={meta} {...otherProps} />}
		</Field>
	);
};
