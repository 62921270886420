import { FC, ReactNode } from 'react';

import LemlistLogo from 'shared/assets/svg-icons/lemlist-logo.svg';
import SourceWhaleLogo from 'shared/assets/svg-icons/source-whale-logo.svg';
import { Text, Dropdown, SkeletonRow } from 'shared/components/ui';
import { ServiceEnum, TopIdsWithFiltersCandidates } from 'shared/generated-models';
import { useModalState } from 'shared/hooks/use-modal-state';
import { useConnectedServices } from 'features/integrations/hooks';
import { PushConfirmModalState } from 'features/integrations/models';

import { SelectLemlistCampaignModal } from '../SelectLemlistCampaignModal';
import { SelectSourceWhaleCampaignModal } from '../SelectSourceWhaleCampaignModal';
import { IntegrationsModal } from '../IntegrationsModal';
import { PushConfirmModal } from '../PushConfirmModal';

import styles from './index.module.scss';

interface Props {
	children?: ReactNode;
	projectId: number;
	candidates: Omit<TopIdsWithFiltersCandidates, 'filters'> & {
		count: number;
	};
	disabled?: boolean;
}

export const IntergationsPushMenu: FC<Props> = ({ children, projectId, candidates, disabled }) => {
	const { data, isLoading } = useConnectedServices();

	const menuDropdown = useModalState();
	const connectIntegrationsModal = useModalState<ServiceEnum>();
	const selectCampaignModal = useModalState<ServiceEnum>();
	const pushConfirmModal = useModalState<PushConfirmModalState>();

	return (
		<>
			<Dropdown
				disabled={disabled}
				trigger={['click']}
				placement="bottomRight"
				dropdownRender={() => (
					<div className={styles.dropdown}>
						<div className={styles.dropdownList}>
							{isLoading ? (
								<SkeletonRow height={42} />
							) : (
								<>
									<button
										className={styles.button}
										onClick={() => {
											if (data.includes(ServiceEnum.LEMLIST)) {
												selectCampaignModal.open(ServiceEnum.LEMLIST);
											} else {
												connectIntegrationsModal.open(ServiceEnum.LEMLIST);
											}

											menuDropdown.close();
										}}>
										<img className={styles.logo} src={LemlistLogo} alt="Lemlist logo" />
										<Text variant="inter/14/medium" className={styles.text}>
											Lemlist
										</Text>
										{!data.includes(ServiceEnum.LEMLIST) && (
											<Text variant="inter/14/medium" className={styles.connect}>
												Connect
											</Text>
										)}
									</button>
									<button
										className={styles.button}
										onClick={() => {
											if (data.includes(ServiceEnum.SOURCEWHALE)) {
												selectCampaignModal.open(ServiceEnum.SOURCEWHALE);
											} else {
												connectIntegrationsModal.open(ServiceEnum.SOURCEWHALE);
											}

											menuDropdown.close();
										}}>
										<img className={styles.logo} src={SourceWhaleLogo} alt="Lemlist logo" />
										<Text variant="inter/14/medium" className={styles.text}>
											SourceWhale
										</Text>
										{!data.includes(ServiceEnum.SOURCEWHALE) && (
											<Text variant="inter/14/medium" className={styles.connect}>
												Connect
											</Text>
										)}
									</button>
								</>
							)}
						</div>
					</div>
				)}
				onOpenChange={open => (open ? menuDropdown.open() : menuDropdown.close())}
				open={menuDropdown.isOpen}>
				{children}
			</Dropdown>

			{connectIntegrationsModal.isOpen && (
				<IntegrationsModal
					onClose={connectIntegrationsModal.close}
					onConnected={() => {
						selectCampaignModal.open(connectIntegrationsModal.data);
						connectIntegrationsModal.close();
					}}
				/>
			)}
			{selectCampaignModal.isOpen &&
				(selectCampaignModal.data === ServiceEnum.LEMLIST ? (
					<SelectLemlistCampaignModal
						onClose={selectCampaignModal.close}
						onSelect={result => {
							selectCampaignModal.close();
							pushConfirmModal.open({
								service: ServiceEnum.LEMLIST,
								result
							});
						}}
					/>
				) : (
					<SelectSourceWhaleCampaignModal
						onClose={selectCampaignModal.close}
						onSelect={result => {
							selectCampaignModal.close();
							pushConfirmModal.open({
								service: ServiceEnum.SOURCEWHALE,
								result
							});
						}}
					/>
				))}
			{pushConfirmModal.isOpen && (
				<PushConfirmModal
					onBack={() => {
						selectCampaignModal.open(pushConfirmModal.data?.service);
						pushConfirmModal.close();
					}}
					onClose={pushConfirmModal.close}
					projectId={projectId}
					candidates={candidates}
					{...pushConfirmModal.data!}
				/>
			)}
		</>
	);
};
