import React, { FC, memo } from 'react';
import cn from 'classnames';

import { OpenedContactShort } from 'shared/generated-models';
import { TableSelectionMode, Tr } from 'features/table';
import { Checkbox, ConfiguredTooltip, Icon, LikelyToMoveTag, RockstarTag, Text } from 'shared/components/ui';
import { formatDate, getProfileInitials, MONTH_DAY_YEAR_FORMAT } from 'shared/utils';
import { CandidateProfileAvatar, ProfileName } from 'shared/components';
import { formatLocationShort, formatTitle } from 'features/search/utils';
import { useIsAdvanced } from 'features/auth/hooks';

import styles from './index.module.scss';

interface Props {
	candidate: OpenedContactShort;
	className?: string;
	checkbox: {
		checked: boolean;
		onChange: () => void;
		mode: TableSelectionMode;
	};
	variant?: 'acquired' | 'project';
	onClick: (contact: OpenedContactShort) => void;
	openedContactsMap?: Record<number, string> | null;
}

export const CandidateRow: FC<Props> = memo(
	({ candidate, className, checkbox, onClick, variant, openedContactsMap }: Props) => {
		const handleClick = () => {
			onClick(candidate);
		};

		const isAdvanced = useIsAdvanced();

		const title = formatTitle(candidate);
		const location = formatLocationShort(candidate.location, candidate.country);

		const isOpen = openedContactsMap ? openedContactsMap.hasOwnProperty(candidate.prog_ai_id) : false;
		const email = openedContactsMap && isOpen ? openedContactsMap[candidate.prog_ai_id] : '';

		const isRockstar = candidate.seniority_level === 'Rock Star';

		return (
			<Tr className={cn(styles.profile, className)}>
				<button className={styles.button} onClick={handleClick} />
				<div className={styles.border}></div>
				<div className={styles.wrapper}>
					<Checkbox
						className={styles.profileCheckbox}
						checked={checkbox.checked}
						onChange={checkbox.onChange}
					/>
					{/* <ConfiguredTooltip isOpen={isOpen} delay={1}>
						<CandidateProfileAvatar
							onClick={handleClick}
							src={candidate.profile_pic_url}
							size={80}
							className={cn(styles.profileAvatar, {
								[styles.profileAvatarBlurred]: variant === 'project' && !isOpen
							})}>
							{getProfileInitials(candidate)}
						</CandidateProfileAvatar>
					</ConfiguredTooltip> */}
					<CandidateProfileAvatar
						onClick={handleClick}
						src={candidate.profile_pic_url}
						size={80}
						className={styles.profileAvatar}>
						{getProfileInitials(candidate)}
					</CandidateProfileAvatar>
					<div className={styles.profileSummary}>
						<div className={styles.profileHeader}>
							{/* <ConfiguredTooltip isOpen={isOpen} delay={1}>
								<span onClick={handleClick}>
									<ProfileName
										variant="inter/16/semi"
										profile={candidate}
										className={cn(styles.profileName, {
											[styles.profileBlurred]: variant === 'project' && !isOpen
										})}
									/>
								</span>
							</ConfiguredTooltip> */}
							<ProfileName variant="inter/16/semi" profile={candidate} className={styles.profileName} />
							{variant === 'project' && (
								<div className={styles.profileHeaderRight}>
									{isAdvanced && <LikelyToMoveTag prob={candidate.likely_to_move_prob} />}
									{isRockstar && <RockstarTag />}
									<ConfiguredTooltip isOpen={isOpen} delay={1}>
										<div className={styles.contactsProject} onClick={handleClick}>
											<Icon icon="mail" size={20} />
											<Icon icon="phone" size={20} />
											<Icon icon="github-logo" size={20} />
											<Icon icon="linkedin-logo" size={20} />
											<Icon icon="twitter-logo" size={20} />
											<Icon icon="stackoverflow-logo-grey" size={20} />
											<Icon icon="website" size={20} />
											<Icon icon="facebook-logo" size={20} />
										</div>
									</ConfiguredTooltip>
								</div>
							)}
						</div>
						<div className={styles.profileBody}>
							<div className={styles.profileTitleWrapper}>
								{title && (
									<Text variant="inter/13/medium" className={styles.profileTitle}>
										{title}
									</Text>
								)}
								<Text variant="inter/13/regular" color="darker_grey">
									{candidate.years_of_experience &&
										(candidate.years_of_experience > 10
											? `10+ y experience`
											: `${candidate.years_of_experience} y experience`)}
								</Text>
							</div>
							<div className={styles.row}>
								<Icon icon="location" size={20} className={styles.iconLocation} />
								<Text variant="inter/13/regular" color="darker_grey">
									{location ? location : 'N/A'}
								</Text>
							</div>
						</div>
						{variant === 'acquired' && (
							<div className={styles.profileFooter}>
								<div className={styles.profileFooterIcons}>
									<a
										className={styles.iconGithub}
										href={candidate.github_url}
										target="_blank"
										rel="noreferrer">
										<Icon icon="github-logo" size={20} />
									</a>
									{candidate.linkedin_url && (
										<a
											className={styles.iconLinkedin}
											href={candidate.linkedin_url}
											target="_blank"
											rel="noreferrer">
											<Icon icon="linkedin-logo" size={20} />
										</a>
									)}
									{candidate.twitter_url && (
										<a href={candidate.twitter_url} target="_blank" rel="noreferrer">
											<Icon icon="twitter-logo" size={20} />
										</a>
									)}
									{candidate.stackoverflow_url && (
										<a href={candidate.stackoverflow_url} target="_blank" rel="noreferrer">
											<Icon icon="stackoverflow-logo" size={20} />
										</a>
									)}
									{candidate.homepage_url && (
										<a
											className={styles.iconWebsite}
											href={candidate.homepage_url}
											target="_blank"
											rel="noreferrer">
											<Icon icon="website" size={20} />
										</a>
									)}
									<Icon className={styles.iconMail} icon="mail" size={20} />
									<a className={styles.contactsLink} href={`mailto:${candidate.primary_email}`}>
										<Text
											variant="inter/14/medium"
											color="light_blue"
											className={styles.contactsMailIcon}>
											{candidate.primary_email}
										</Text>
									</a>
								</div>
								<div>
									<Text variant="inter/13/regular" color="grey">
										Added on {formatDate(candidate.added_at, MONTH_DAY_YEAR_FORMAT)} / Expires&nbsp;
									</Text>
									<Text variant="inter/13/medium" color="darker_grey">
										{formatDate(candidate.expires_at, MONTH_DAY_YEAR_FORMAT)}
									</Text>
								</div>
							</div>
						)}
					</div>
				</div>
			</Tr>
		);
	},
	arePropsEqual
);

function arePropsEqual(oldProps: Props, newProps: Props) {
	return (
		oldProps.checkbox.checked === newProps.checkbox.checked &&
		oldProps.checkbox.mode === newProps.checkbox.mode &&
		oldProps.openedContactsMap === newProps.openedContactsMap
	);
}
